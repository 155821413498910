<script>
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable prettier/prettier */
</script>
<template>
  <!--  Edit Lead -->

  <v-card>
    <v-card-title primary-title> Edit Lead </v-card-title>

    <v-card-text class="mt-3">
      <v-overlay absolute :value="loadingAcciones">
        <v-progress-circular indeterminate size="34"></v-progress-circular>
      </v-overlay>
      <v-form ref="registerForm" v-model="formValid">
        <v-container>
          <v-row class="texts1" no-gutters>
            <v-col cols="12" sm="4" class="pr-1">
              <label class="font-weight-medium" for="name"> First Name:</label>
              <v-text-field
                outlined
                class="rounded"
                dense
                v-model="itemEdit.name"
              ></v-text-field
            ></v-col>
            <v-col cols="12" sm="4">
              <label class="font-weight-medium" for="lastname"> Last Name: </label>
              <v-text-field
                class="rounded"
                dense
                v-model="itemEdit.last_name"
                outlined
              ></v-text-field
            ></v-col>
            <v-col cols="6" sm="2" class="px-1">
              <label class="font-weight-medium" for="gender">Gender: </label>
              <v-select
                :items="genders"
                item-text="label"
                item-value="value"
                v-model="itemEdit.isMale"
                outlined
                dense
              ></v-select>
            </v-col>
            <v-col cols="6" sm="2" class="pl-1">
              <label class="font-weight-medium" for="gender">Preferred Language: </label>
              <v-select
                :items="langs"
                item-text="label"
                item-value="value"
                v-model="itemEdit.preferLanguage"
                outlined
                dense
              ></v-select>
            </v-col>
          </v-row>
          <v-row class="texts1" no-gutters>
            <v-col cols="12" sm="3">
              <label class="font-weight-medium" for="dob"> Date of Birth: </label>
              <ma-date-picker v-model="itemEdit.birthDate" past :editable="true"
            /></v-col>

            <v-col cols="12" sm="3" class="px-1">
              <label class="font-weight-medium" for="phone"> Phone: </label>
              <v-text-field
                class="rounded"
                prepend-inner-icon="mdi-numeric-positive-1"
                autocomplete="tel"
                dense
                :rules="[rules.phone]"
                v-model="phonelead"
                outlined
              ></v-text-field
            ></v-col>
            <v-col cols="12" sm="6">
              <label class="font-weight-medium" for="email"> Email: </label>
              <v-text-field
                class="rounded"
                dense
                :rules="[rules.email]"
                v-model="itemEdit.email"
                outlined
              ></v-text-field
            ></v-col>
          </v-row>

          <v-row class="texts1" no-gutters>
            <v-col cols="12" sm="3">
              <label class="font-weight-medium" for="source"> Source: </label>
              <v-autocomplete
                v-model="itemEdit.sourceId"
                :loading="loadingRefer"
                :items="references"
                item-text="name"
                item-value="id"
                outlined
                dense
            /></v-col>
            <v-col cols="12" sm="3" class="px-1">
              <label class="font-weight-medium" for="status"> Status: </label>
              <v-autocomplete
                v-model="itemEdit.statusNom"
                :items="status"
                item-text="name"
                item-value="id"
                outlined
                dense
              />
            </v-col>
            <v-col cols="12" sm="4" class="px-1">
              <label class="font-weight-medium" for="metal">Tag: </label>
              <v-autocomplete
                :items="leadTags"
                item-text="name"
                item-value="id"
                multiple
                v-model="tagIds"
                :disabled="leadTags.length == 0"
                prepend-inner-icon="mdi-magnify"
                clearable
                outlined
                dense
              >
                <template v-slot:prepend-inner>
                  <template v-if="!checkAllTags && leadTags.length != 0">
                    <v-slide-x-reverse-transition mode="out-in">
                      <v-tooltip top>
                        <template v-slot:activator="{ on }">
                          <v-icon
                            v-on="on"
                            color="success"
                            @click="checkAllTags = true"
                            v-text="'mdi-check-all'"
                          ></v-icon>
                        </template>
                        <span>Select All Tags</span>
                      </v-tooltip>
                    </v-slide-x-reverse-transition>
                  </template>
                </template>
              </v-autocomplete>
            </v-col>

            <!--  <v-col cols="12" sm="3" class="px-1">
              <label class="font-weight-medium" for="source"
                >Metal Plan:
              </label>
              <v-select
                :items="metalplan"
                item-text="label"
                item-value="value"
                v-model="itemEdit.policyPlan"
                outlined
                dense
              ></v-select>
            </v-col>

            <v-col cols="12" sm="3" class="pr-1">
              <label class="font-weight-medium" for="members"> Members: </label>
              <v-text-field
                v-model="itemEdit.members"
                outlined
                type="Number"
                dense
              ></v-text-field>
            </v-col>
            <v-col cols="12" sm="3">
              <label class="font-weight-medium" for="company"> Company: </label>
              <v-autocomplete
                v-model="itemEdit.companyId"
                :loading="loadingRefer"
                :items="companies"
                item-text="name"
                item-value="id"
                outlined
                dense
            /></v-col> -->
          </v-row>
        </v-container>
      </v-form>
      <v-alert dense class="actions mt-2" type="error" :value="uuidexist != null">
        {{ `This ${getpatientN} already exists` }}
      </v-alert>
    </v-card-text>
    <v-card-actions class="actions">
      <v-spacer></v-spacer>
      <v-btn text @click="cancelEdit" color="error">Cancel</v-btn>
      <template v-if="uuidexist != null">
        <v-btn color="green darken-1" text @click="gotoPatient">
          {{ "View " + getpatientN }}
        </v-btn>
      </template>
      <v-btn
        text
        :disabled="!formValid || uuidexist != null"
        :loading="loadingAcciones"
        @click="confirmEdit"
        color="primary"
        >Edit</v-btn
      >
    </v-card-actions>
  </v-card>
</template>
<script>
import rules from "@/components/account/rules";
import { mapState, mapActions, mapGetters } from "vuex";
import { getAPI } from "@/api/axios-base";
import MaDatePicker from "@/components/MaDatePicker/MaDatePicker.vue";
import { desireSurgery } from "@/utils/consts";
import formatPhone, { cleanPhoneNumber } from "@/utils/formatPhone";
import { notifyError, notifySuccess } from "@/components/Notification";
import moment from "moment";
import { isAdmin } from "@/store/getters";
export default {
  name: "edit-lead",
  components: { MaDatePicker },

  data() {
    return {
      item: null,

      editDOB: false,
      loadingAcciones: false,
      uuidexist: null,
      rules: {
        email: rules.emailnull,
        required: rules.required,
        phone: rules.isPhone1,
        maxCharsfull: (v) => v.length >= 3 || this.$t("min3Chars"),
      },
      types: [],
      status: [],
      formValid: false,

      dialogEdit: false,
      phonelead: "",
      tagIds: null,
      tagsAdmins: [],
      checkAllTags: false,
      itemEdit: {
        leadUuid: null,
        preferLanguage: "ENGLISH",
        name: null,
        last_name: null,
        phone: null,
        email: null,
        tagIds: null,
        sourceId: null,
        statusNom: null,
        birthDate: null,
        isMale: false,
      },
      langs: [
        {
          label: "English",
          value: "ENGLISH",
        },
        {
          label: "Spanish",
          value: "SPANISH",
        },
      ],
      genders: [
        { label: "Female", value: false },
        { label: "Male", value: true },
      ],
      metalplan: [
        { label: "Bronze", value: "BRONZE" },
        { label: "Silver", value: "SILVER" },
        { label: "Gold", value: "GOLD" },
      ],
    };
  },

  watch: {
    phonelead: {
      async handler() {
        this.phonelead = cleanPhoneNumber(this.phonelead);
        if (this.phonelead[0] == "1") {
          this.phonelead = this.phonelead.slice(1);
        }
        this.itemEdit.phone = this.phonelead;
        if (this.phonelead.length == 10) {
          await this.actFilterPatient({
            query: this.phonelead,
            range: { limit: 100, offset: 0 },
          });
          if (this.patients.length != 0) {
            this.uuidexist = this.patients[0].uuid;
          } else {
            this.uuidexist = null;
          }
        } else if (this.phonelead.length == 0) {
          this.uuidexist = null;
        }
      },
    },
    tagIds: {
      handler(val) {
        this.itemEdit.tagIds = val;
        if (val && val.length != this.leadTags.length) {
          this.checkAllTags = false;
        } else if (
          val &&
          val.length == this.leadTags.length &&
          this.checkAllTags == false
        ) {
          this.checkAllTags = true;
        }
      },
    },
    checkAllTags(val) {
      if (val) {
        if (this.tagIds == null || this.leadTags.length != this.tagIds.length) {
          this.tagIds = this.leadTags.map((t) => {
            return t.id;
          });
        }
      }
    },
  },
  async mounted() {
    await this.loadLeadStatus();
    await this.actListReferences();
    await this.actListTags();
    await this.actListCompaniesNom();

    this.getLeadData();
    localStorage.setItem("leadcenter", true);
  },
  computed: {
    ...mapState("crmMedicFormModule", ["patients"]),
    ...mapState("crmSettingsModule", ["loadingRefer", "references", "companies", "tags"]),
    ...mapGetters([
      "getCoordinatorN",
      "getpatientN",
      "isAdmin",
      "isSuper",
      "isSupervisor",
      "getUuid",
    ]),

    isAdmins() {
      return this.isAdmin || this.isSuper;
    },

    leadTags() {
      const lt = this.tags.sort((a, b) => {
        return b.isLead - a.isLead;
      });
      if (this.isAdmins) {
        return lt;
      } else {
        let arraytags = lt;
        for (let index = 0; index < this.tagsAdmins.length; index++) {
          const element = this.tagsAdmins[index].tagNom;
          arraytags = arraytags.filter((at) => at.id != element.id);
        }
        return arraytags;
      }
    },

    dateSend() {
      if (this.itemEdit.birthDate == null || this.itemEdit.birthDate == undefined) {
        return "";
      }
      const f = moment(this.itemEdit.birthDate).format("YYYY-DD-MM");
      return f;
    },

    dobShow() {
      if (this.itemEdit.birthDate == null || this.itemEdit.birthDate == undefined) {
        return "";
      }
      const f = moment(this.itemEdit.birthDate).format("MM-DD-YYYY");
      return f;
    },
  },

  methods: {
    ...mapActions("crmMedicFormModule", ["actFilterPatient"]),
    ...mapActions("crmSettingsModule", [
      "actListReferences",
      "actListCompaniesNom",
      "actListTags",
    ]),
    gotoPatient() {
      this.$router.push(`/clients/details/${this.uuidexist}`);
    },
    getLeadData() {
      this.loadingAcciones = true;
      const leaduuid = this.$route.params.uuid;
      getAPI("/social/getLead/" + leaduuid)
        .then((res) => {
          this.item = res.data;
          this.loadingAcciones = false;
          this.init();
        })
        .catch((error) => {
          let mess = error.response.data.message.toString();
          this.loadingAcciones = false;

          notifyError(error.response.data, `An error occurred: ${mess}`);
        });
    },
    cancelEdit() {
      const leaduuid = this.$route.params.uuid;
      if (localStorage.getItem("from") == "lead") {
        this.$router.push(`/leads_details/${leaduuid}`);
      } else {
        this.$router.push("/leads_center");
      }
    },

    confirmEdit() {
      this.loadingAcciones = true;
      let body = Object.assign({}, this.itemEdit);
      body.phone = "+1" + body.phone;

      body = this.cleanNull(body);
      getAPI
        .put("/social/updateCounts", body)
        .then(() => {
          notifySuccess("Lead has been Edited");
          this.loadingAcciones = false;
          this.cancelEdit();
        })
        .catch((error) => {
          this.loadingAcciones = false;
          let mess = error.response.data.message.toString();

          notifyError(error.response.data, `An error occurred: ${mess}`);
        });
    },

    loadProcedures() {
      getAPI("/procedure-form-nom/list").then((res) => {
        this.types = res.data;
      });
    },
    init() {
      if (this.item) {
        let pho = this.item.phone;
        if (pho.includes("+1")) {
          pho = pho.replace("+1", "");
        }
        pho = pho.replaceAll("\t", "");
        pho = pho.replaceAll(" ", "");
        pho = formatPhone(pho);
        this.phonelead = pho;
        this.itemEdit = {
          leadUuid: this.item.uuid,
          name: this.item.name,
          preferLanguage: this.item.preferLanguage,
          last_name: this.item.last_name,
          phone: pho,
          email: this.item.email,
          sourceId: this.item.sourceNom ? this.item.sourceNom.id : null,
          tagIds: [],
          statusNom: this.item.statusNom ? this.item.statusNom.id : null,
          birthDate: this.item.birthDate,
          isMale: this.item.isMale,
        };

        if (this.item.leadTagsNom) {
          if (this.isAdmins) {
            console.log(this.item.leadTagsNom);
            this.tagIds = this.item.leadTagsNom.map((tn) => {
              return tn.tagNom.id;
            });
          } else {
            this.tagsAdmins = this.item.leadTagsNom.filter((ptn) => ptn.asAdmin);
            const tagsNoAdmin = this.item.leadTagsNom.filter((ptn) => !ptn.asAdmin);
            this.tagIds = tagsNoAdmin.map((tn) => {
              return tn.tagNom.id;
            });
          }
        } else {
          this.tagIds = null;
        }
      }
    },

    loadLeadStatus() {
      getAPI(`/lead-status-nom/list`)
        .then((res) => {
          this.status = res.data.filter((f) => f.status != "NO_ACTIVE");
        })
        .catch((error) => {
          let mess = error.response.data.message.toString();
          notifyError(error.response.data, `An error occurred: ${mess}`);
        });
    },
    cleanNull(obj) {
      for (var propName in obj) {
        if (
          obj[propName] === null ||
          obj[propName] === undefined ||
          obj[propName] === ""
        ) {
          delete obj[propName];
        }
      }
      return obj;
    },
  },
};
</script>
<style lang="scss" scoped>
.cardexpand {
  width: 100vh !important;
}
.texts {
  margin-top: -26px !important;
  margin-bottom: 10px !important;
}
.texts1 {
  margin-top: -20px !important;
  margin-bottom: 10px !important;
}
.texts2 {
  margin-top: -40px !important;
  margin-bottom: 8px !important;
}
.actions {
  margin-top: -30px !important;
}
.texts3 {
  margin-top: -40px !important;
  margin-bottom: 5px !important;
}
.field {
  padding: 0 15px;
}
.heightfield {
  margin-top: -27px !important;
}
</style>
